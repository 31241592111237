@import './variables';

:root {
  --color-primary: #{$color-primary};
  --color-primary-dark: #{$color-primary-dark};
  --color-primary-light: #{$color-primary-light};
  --color-primary-very-light: #{$color-primary-very-light};
  --color-secondary: #{$color-secondary};
  --color-tertiary: #{$color-tertiary};
  --color-negative: #{$color-negative};
  --color-negative-bg: #{$color-negative-bg};
  --color-neutral: #{$color-neutral};
  --color-neutral-bg: #{$color-neutral-bg};
  --color-positive: #{$color-positive};
  --color-positive-bg: #{$color-positive-bg};
  --color-utility-pink: #{$color-utility-pink};
  --color-karaka-grey: #{$color-karaka-grey};
  --color-dark-cool-grey: #{$color-dark-cool-grey};
  --color-mid-cool-grey: #{$color-mid-cool-grey};
  --color-light-grey: #{$color-light-grey};
  --color-light-cool-grey: #{$color-light-cool-grey};
  --color-cool-blue-grey: #{$color-cool-blue-grey};
  --color-cool-blue-grey-light: #{$color-cool-blue-grey-light};
  --color-tranquil-grey: #{$color-tranquil-grey};
  --color-tranquil-grey_darken-10: #{darken(
      $color: $color-tranquil-grey,
      $amount: 10
    )};
  --color-dark-dim: #28282499;
  --color-light-blue-grey: #{$color-light-blue-grey};
  --color-label-blue: #{$color-label-blue};
  --color-label-red: #{$color-label-red};
  --color-mercury: #{$color-mercury};
  --color-background-main: #{$color-background-main};
  --color-background-light: #{$color-background-light};
  --color-background-hover-light: #{$color-background-hover-light};
  --color-background-hover: #{$color-background-hover};
  --color-background-red: #{$color-background-red};
  --color-border-hover: #{$color-border-hover};
  --color-text-placeholder: #{$color-text-placeholder};
  --color-text-dark: #{$color-text-dark};
  --color-text-light: #{$color-text-light};
  --color-text-extra-light: #{$color-text-extra-light};
  --color-text-inversed: #{$color-text-inversed};
  --color-input-border: #{$color-input-border};
  --color-button-hover: #{$color-button-hover};
  --color-button-border: #{$color-button-border};
  --color-button-border-hover: #{$color-button-border-hover};
  --color-button-active: #{$color-button-active};
  --color-button-border-active: #{$color-button-border-active};
  --color-button-background: #{$color-button-background};
  --color-button-alert: #{$color-button-alert};
  --color-button-border-alert: #{$color-button-border-alert};
  --color-button-disabled: #{$color-button-disabled};
  --color-button-background-disabled: #{$color-button-background-disabled};
  --color-button-border-disabled: #{$color-button-border-disabled};
  --color-widget-disabled: #{$color-widget-disabled};
  --color-widget-background-disabled: #{$color-widget-background-disabled};
  --color-widget-placeholder: #{$color-widget-placeholder};
  --color-widget-background-alert: #{$color-widget-background-alert};
  --color-widget-border-alert: #{$color-widget-border-alert};
  --color-widget-border: #{$color-widget-border};
  --color-select-option-background-base: #{$color-select-option-background-base};
  --color-select-option-background-selected: #{$color-select-option-background-selected};
  --color-select-option-background-focused: #{$color-select-option-background-focused};

  /* New colors schema */
  --color-interactive: #{$color-interactive};
  --color-focus-1: #{$color-focus-1};
  --color-focus-2: #{$color-focus-2};
  --color-selected-1: #{$color-selected-1};
  --color-text-heading: #{$color-text-heading};
  --color-text-body: #{$color-text-body};
  --color-background-primary: #{$color-background-primary};
  --color-background-secondary: #{$color-background-secondary};
  --color-keyline: #{$color-keyline};
  --color-error: #{$color-error};
  --color-warning: #{$color-warning};
  --color-success: #{$color-success};
  --color-dark-green: #{$color-dark-green};
  --color-dark-red: #{$color-dark-red};
}
