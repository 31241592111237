@import './variables';

%component-styles {
  font-family: 'Avenir', -apple-system, sans-serif;

  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: antialiased;
}

@mixin component {
  &:not(i),
  & *:not(i) {
    @extend %component-styles;
  }
}

@mixin box-sizing-border-box {
  &,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
