@import '../../styles/variables';

.iconWrapper {
  display: inline;
}

.icon {
  fill: $color-button-disabled;
}

:global(.bl-field-error) {
  .select {
    box-shadow: inset 0 0 0 var(--medkit-input-border-width, 0.125rem)
        var(--medkit-input-box-shadow-color, $color-keyline),
      0 0 0 var(--medkit-input-border-width, 0.25rem) var(--color-negative);

    &.select-focused {
      box-shadow: inset 0 0 0 var(--medkit-input-border-width, 0.125rem)
          var(--medkit-input-box-shadow-color-active, $color-secondary),
        0 0 0 var(--medkit-input-border-width, 0.25rem) var(--color-negative);
    }
  }
}

.select {
  align-items: flex-start;
  box-shadow: inset 0 0 0 var(--medkit-input-border-width, 0.125rem)
    var(--medkit-input-box-shadow-color, $color-keyline);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  overflow: auto;
  position: relative;

  &.select-read-only {
    box-shadow: none;
    display: block;
    font-weight: normal;

    span:not(:last-of-type) {
      padding-right: 5px;

      &::after {
        content: ',';
      }
    }
  }

  &.select-disabled {
    background-color: $color-background-light;
  }

  &.select-focused {
    box-shadow: inset 0 0 0 var(--medkit-input-border-width, 0.125rem)
        var(--medkit-input-box-shadow-color-active, $color-secondary),
      0 0 0 var(--medkit-input-border-width, 0.25rem)
        var(--medkit-input-border-color-active, $color-focus-1);
  }

  .select-selector {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    line-height: 22px;
    max-height: 200px;
    min-height: 36px;
    overflow: auto;
    padding: 4px 2rem 4px 7px;
    width: 100%;
  }

  .select-selection-overflow {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow: auto;
  }

  .select-selection-overflow-item-suffix {
    width: 100%;
  }

  &.select-allow-clear {
    .select-selector {
      padding-right: 4rem;
    }
  }

  .select-arrow,
  .select-clear {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
  }

  .select-arrow + .select-clear {
    right: 38px;
  }

  .select-clear {
    font-size: 20px;
    pointer-events: unset;
    cursor: pointer;
  }

  .select-arrow-icon {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6.5px 0 6.5px;
    border-color: $color-mid-cool-grey transparent transparent transparent;
    transition: transform 0.2s ease-in;
  }

  .select-selection-search {
    display: block;
    flex: 1;
    margin: 4px;
    //stylelint-disable-next-line declaration-no-important
    width: unset !important;
  }

  .select-selection-search-mirror {
    display: none;
  }

  &.select-open .select-arrow-icon {
    transform: rotate(-180deg);
  }

  &.select-single .select-selection-item,
  .select-selection-placeholder {
    position: absolute;
    top: 8px;
  }

  .select-selection-placeholder {
    color: $mediumgray;
    pointer-events: none;
    width: 100%;
  }

  .select-selection-search-input {
    padding: 0;
    margin: 0;
    outline: none;
    border: 0;
    background: transparent;
    font-size: 14px;
    width: 100%;
    height: 100%;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 0;
      width: 0;
    }
  }

  &.select-multiple .select-selection-item {
    align-items: flex-start;
    background-color: var(
      --medkit-checkbox-primary,
      var(--medkit-primary, $color-secondary)
    );
    border-color: var(
      --medkit-checkbox-primary,
      var(--medkit-primary, $color-secondary)
    );
    border-radius: 2px;
    color: $white100;
    display: flex;
    flex-direction: row-reverse;
    line-height: $line-height-body;
    margin: 3px;
    padding: 0 0.5rem;
    position: relative;

    .select-selection-item-remove {
      cursor: pointer;
      margin-right: 0.5rem;
    }
  }
}

.select-dropdown {
  box-shadow: 0 4px 9px 3px rgba(0, 6, 61, 0.2);
  border-radius: 4px;
  overflow: hidden;
  margin-top: 100px;
  background-color: white;
  cursor: default;
  z-index: 9000;

  &.select-dropdown-hidden {
    display: none;
  }

  .select-item,
  .select-item-empty {
    padding: 8px 17px;
  }

  .select-item-empty {
    color: $color-light-cool-grey;
  }

  .select-item-option-selected {
    background-color: rgba(74, 144, 226, 0.1);
  }

  .select-item-option:hover,
  .select-item-option-active {
    background-color: rgba(74, 144, 226, 0.05);
  }

  .select-item-option-state {
    display: none;
  }
}
