@import '@babylon/design-tokens/all.scss';

$border-radius: 4px;
$border-radius-small: 2px;
$font: #{$font-family};

$max-width: 1024px;

/* Medkit color scheme */
$color-interactive: $purple100;
$color-focus-1: $purple50;
$color-focus-2: $blue100;
$color-selected-1: $violet;
$color-text-heading: $grey90;
$color-label: $grey90;
$color-text-body: $shuttle-gray;
$color-background-primary: $white100;
$color-background-secondary: $lightgray;
$color-keyline: $slategrey;
$color-button-disabled: $grey-500;
$color-dark-border: $grey-500;
$color-border: $slategrey;
$color-border-light: $grey-light;
$color-error: $red120;
$color-warning: $orange100; // Should be Warning orange #D47602
$color-success: $green100; // Should be Positive green #008033
$color-dark-green: $green-d20;
$color-dark-red: $red120;
$color-table-row-background: $lightgray;

/* Spacings */

/* Text sizes */
$font-size-heading-1: 36px;
$line-height-heading-1: 48px;
$font-size-heading-2: 30px;
$line-height-heading-2: 40px;
$font-size-heading-3: 24px;
$line-height-heading-3: 32px;
$font-size-heading-4: 20px;
$line-height-heading-4: 28px;
$font-size-heading-5: 16px;
$line-height-heading-5: 24px;
$font-size-body: 16px;
$line-height-body: 24px;
$font-size-caption: 12px;
$line-height-caption: 16px;

/* Screen break points */
$small-bp: $small-breakpoint;
$medium-bp: $medium-breakpoint;
$large-bp: $large-breakpoint;
$xlarge-bp: $extra-large-breakpoint;

$color-primary: #2bbbbb;
$color-primary-dark: #26a6a6;
$color-primary-light: scale-color($color-primary, $lightness: 90%);
$color-primary-very-light: scale-color($color-primary, $lightness: 96%);
$color-secondary: #400099;
$color-tertiary: #f45da9;

$color-negative: #ff3b2f;
$color-negative-bg: #ffebea;
$color-neutral: #ffb561;
$color-neutral-bg: scale-color($color-neutral, $lightness: 90%);
$color-positive: $color-primary;
$color-positive-bg: $color-primary-light;

$color-utility-pink: #fdeef6;
$color-karaka-grey: #282824;
$color-dark-cool-grey: #333f52;
$color-mid-cool-grey: #637280;
$color-light-grey: #b7bfcc;
$color-light-cool-grey: #bfc3cb;
$color-cool-blue-grey: #c5d0de;
$color-cool-blue-grey-light: #d9e4f0;
$color-tranquil-grey: #dee7ea;
$color-light-blue-grey: #e5effb;
$color-label-blue: #3761bf;
$color-label-red: $color-negative;
$color-mercury: #e5e5e5;

$color-background-main: #fff;
$color-background-light: #f8fafb;
$color-background-hover-light: #e3e8ef;
$color-background-hover: #898989;
$color-background-red: #ff6a61;

$color-border-hover: #c2c9d2;

$color-text-placeholder: #aab7c4;
$color-text-dark: #353a46;
$color-text-light: #6b7785;
$color-text-extra-light: #898989;
$color-text-inversed: #fff;
$color-input-border: #ccc;

$color-button-hover: #f2f2f2;
$color-button-border: #dfe7eb;
$color-button-border-hover: #cfdbe1;
$color-button-active: $color-mercury;
$color-button-border-active: #c0cfd7;
$color-button-background: #f9f9f9;
$color-button-alert: #ffa7a7;
$color-button-border-alert: #ffa7a7;
$color-button-disabled: #8c8c8c;
$color-button-background-disabled: #eaeaea;
$color-button-border-disabled: #bababa;

$color-widget-disabled: #808080;
$color-widget-background-disabled: #f9f9f9;
$color-widget-placeholder: rgba($color-text-light, 0.5);
$color-widget-background-alert: #fcf5f5;
$color-widget-border-alert: #f98b8b;
$color-widget-border: #ccc;

$color-select-option-background-base: #007eff;
$color-select-option-background-selected: rgba(
  $color-select-option-background-base,
  0.04
);
$color-select-option-background-focused: rgba(
  $color-select-option-background-base,
  0.08
);
