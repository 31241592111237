@import './variables';

html,
body,
#root {
  display: flex;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  width: 100vw;
}

body {
  color: var(--color-text-body);
  font-family: $font;
  font-size: $font-size-body;
  line-height: $line-height-body;
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

p {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 16px;
  color: $color-text-light;
}

ul {
  margin: 0 0 16px;
  padding: 0 0 0 28px;
}

li {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: $color-text-light;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  color: $color-text-heading;
}

p + h1,
p + h2,
p + h3,
p + h4 {
  margin-top: 32px;
}

h1 {
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 32px;
}

h2 {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 24px;
}

h3 {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 16px;
}

h4 {
  font-size: 14px;
  line-height: 16px;
}

fieldset {
  border: 0 none;
  padding: 0;
  margin: 0 0 16px 0;

  > legend {
    color: $color-text-light;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    line-height: 22px;
    margin-bottom: 4px;
  }
}

/* MedKit overrides */
body .medkit__Modal__dialogOverlay {
  z-index: 7;

  .medkit__ModalContent__ModalContent {
    display: flex;
    flex-direction: column;

    .medkit__ModalContent__ModalContentTitle {
      margin-bottom: 2rem;
    }

    .medkit__ModalContent__ModalContentBody {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
    }

    .medkit__ModalContent__ModalContentButtons {
      margin: 0;
    }

    .medkit__Button__ButtonGeneric {
      margin: 0;

      @media (min-width: $medium-bp) {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
}

.medkit__Button__ButtonPrimary:last-child {
  margin-right: 0;
}
